import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {ISpq7Data, ISpq7MetaData, ExtendedISpq7Data} from '../../../firebase/types/spq7.type';
import {DeviceName, Spq7ChartData, Spq7ChartDisplay} from '../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen, secondsToIso } from '../../../utils/timestamp';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryISpq7Data} from '../../../firebase/types/spq7.type';
import { modeToNumber } from '../../../utils/acstatus';
import './modal.css';
import './buttonstyles.css';

interface QseModalProps {
  show: boolean;
  onHide: () => void;
  brightness: number;
  setBrightness: (value: number) => void;
  deviceIdSelected:string;
}

type State = {
  flattenedPointset: Array<ExtendedISpq7Data>,
  metadata: Array<ISpq7MetaData>,
  brickschema: {},
  loading: false,
  device: [],
  chartData: Spq7ChartData;
  chartDisplay: Spq7ChartDisplay;
};

class QseModal extends Component<QseModalProps, State> {
  unsubscribe: () => void;

  componentDidUpdate(prevProps: QseModalProps) {
    // Check if deviceIdSelected has changed
    if (this.props.deviceIdSelected !== prevProps.deviceIdSelected && this.props.deviceIdSelected.startsWith("QSE")) {
        this.runFirstDatabaseQuery(); // Call the function to fetch new data based on the new deviceIdSelected
    }
}

  componentDidMount() {
    // this.fetchTableData();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
  }
  }

  constructor(props: QseModalProps) {
    super(props);
    
    this.state = {
      flattenedPointset: [],
      metadata: [],
      brickschema: {},
      loading: false,
      device: [],
      chartData:[
        { timestamp: "2022-08-28T10:50:00.000Z", 
          co2_level: 1,
          total_volatile_organic_compounds:1,
          air_quality_index: 1,
          temperature:33.8,
          pressure:20,
          humidity: 25.2,
          leq_sound_level: 3,
          illuminance_sensor:3,
          compare_co2_level: 1,
          compare_total_volatile_organic_compounds:1,
          compare_air_quality_index: 1,
          compare_temperature: 33,
          compare_pressure:20,
          compare_humidity: 25,
          compare_leq_sound_level: 3,
          compare_illuminance_sensor: 3
        },
        { timestamp: "2022-09-04T08:30:00.000Z", 
          co2_level: 1,
          total_volatile_organic_compounds:1,
          air_quality_index: 1,
          temperature:33.8,
          pressure:20,
          humidity: 25.2,
          leq_sound_level: 3,
          illuminance_sensor:3,
          compare_co2_level: 1,
          compare_total_volatile_organic_compounds:1,
          compare_air_quality_index: 1,
          compare_temperature: 33,
          compare_pressure:20,
          compare_humidity: 25,
          compare_leq_sound_level: 3,
          compare_illuminance_sensor: 3
        }
      ],
      chartDisplay:{
        co2_level: true,
        total_volatile_organic_compounds:true,
        air_quality_index: true,
        temperature: true,
        pressure:true,
        humidity: true,
        leq_sound_level: true,
        illuminance_sensor: true,
        compare_co2_level: false,
        compare_total_volatile_organic_compounds:false,
        compare_air_quality_index: false,
        compare_temperature: false,
        compare_pressure:false,
        compare_humidity: false,
        compare_leq_sound_level: false,
        compare_illuminance_sensor: false
      },
    };
    this.runFirstDatabaseQuery();
    this.unsubscribe = () => { };
  }

  flattenObjects(historyObjectArray: HistoryISpq7Data[]){
    console.log('flattenObjects');
    let chartData: Spq7ChartData = historyObjectArray.map(item => ({
        timestamp: secondsToIso(item.timestamp.seconds),
        co2_level: item.points.co2_level?.present_value ?? 0,
        total_volatile_organic_compounds: item.points.total_volatile_organic_compounds?.present_value ?? 0,
        air_quality_index: modeToNumber(item.points.air_quality_index?.present_value) ?? 0,
        temperature: item.points.temperature?.present_value ?? 0,
        pressure: item.points.pressure?.present_value ?? 0,
        humidity: item.points.humidity?.present_value ?? 0,
        leq_sound_level: item.points.leq_sound_level?.present_value ?? 0,
        illuminance_sensor: item.points.illuminance_sensor?.present_value ?? 0,
        compare_co2_level:  0,
        compare_total_volatile_organic_compounds:  0,
        compare_air_quality_index:  0,
        compare_temperature: 0,
        compare_pressure: 0,
        compare_humidity: 0,
        compare_leq_sound_level: 0,
        compare_illuminance_sensor: 0
      }));
      console.log('flattenObjects' + chartData.length);
    return chartData;
};

  checkLevel(deviceId:String) {
    // Split the string by '-' and check if it has the expected format
    const parts = deviceId.split('-');
    if (parts.length === 2) {
        const firstTwoDigits = parts[1].slice(0, 2); // Extract the first two digits after '-'

        if (firstTwoDigits === "00") {
            return "Level-01";
        } else if (firstTwoDigits === "01") {
            return "Level-00";
        } else {
            return "Level-01";
        }
    } else {
        return "Level-01";
    }
}

  runFirstDatabaseQuery = async () => {  
    let queryResult: HistoryISpq7Data[] = [];
    // Set the end date to the current time
    const end = new Date();
    const deviceIdSelected = this.props.deviceIdSelected;

    console.log("modal runFirstDatabaseQuery:" + deviceIdSelected)

    // Set the start date to 8 hours before the end date
    const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
    console.log('runFirstDatabaseQuery startDate:' + start + ' endDate:' + end);

    //LuminaireDataService.getDeviceTimeseries(this.props.level
    LuminaireDataService.getDeviceTimeseries(this.checkLevel(deviceIdSelected), deviceIdSelected, start, end)
    .get()
    .then((querySnapshot: any) => {
        querySnapshot.forEach((doc:any) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            queryResult.push(doc.data());
        });
    }).then(()=> {
        console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
        this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
          // this.handleFilterData();
      });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });

  };

  render() {
    const { show, onHide, brightness, setBrightness } = this.props;
    const { chartData, chartDisplay} = this.state;

    const calculateMaxYValue = (chartData:Spq7ChartData, chartDisplay:Spq7ChartDisplay) => {
      if (!Array.isArray(chartData) || chartData.length === 0) {
        return 100;
      }

      return Math.max(
        ...chartData.map(d => Math.max(
          chartDisplay.co2_level ? d.co2_level ?? 0 : 0,
          chartDisplay.total_volatile_organic_compounds ? d.total_volatile_organic_compounds ?? 0 : 0,
          chartDisplay.air_quality_index ? d.air_quality_index ?? 0 : 0,
          chartDisplay.temperature ? d.temperature ?? 0 : 0,
          chartDisplay.pressure ? d.pressure ?? 0 : 0,
          chartDisplay.humidity ? d.humidity ?? 0 : 0,
          chartDisplay.leq_sound_level ? d.leq_sound_level ?? 0 : 0,
          chartDisplay.illuminance_sensor ? d.illuminance_sensor ?? 0 : 0,
          chartDisplay.compare_co2_level ? d.compare_co2_level ?? 0 : 0,
          chartDisplay.compare_total_volatile_organic_compounds ? d.compare_total_volatile_organic_compounds ?? 0 : 0,
          chartDisplay.compare_air_quality_index ? d.compare_air_quality_index ?? 0 : 0,
          chartDisplay.compare_temperature ? d.compare_temperature ?? 0 : 0,
          chartDisplay.compare_pressure ? d.compare_pressure ?? 0 : 0,
          chartDisplay.compare_humidity ? d.compare_humidity ?? 0 : 0,
          chartDisplay.compare_leq_sound_level ? d.compare_leq_sound_level ?? 0 : 0,
          chartDisplay.compare_illuminance_sensor ? d.compare_illuminance_sensor ?? 0 : 0
        ))
      );
    };

    const RenderRechart = (chartData:Spq7ChartData, chartDisplay:Spq7ChartDisplay) => {
      return(
        <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={createHumanTimestamp}
            // ticks={this.calculateTicks(chartData, 7)}
            angle={-45} 
            textAnchor="end"
            height={120}
          />
          <YAxis label={{ value: "#", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
          {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
          <YAxis label={{ value: "#", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 1200]} interval={0}/>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {chartDisplay.co2_level ? (<Line yAxisId="right" type="monotone" dataKey="co2_level" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.total_volatile_organic_compounds ? (<Line yAxisId="right" type="monotone" dataKey="total_volatile_organic_compounds" stroke="#82ca9d" />) : null}
          {chartDisplay.air_quality_index ? (<Line yAxisId="left" type="monotone" dataKey="air_quality_index" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.temperature ? (<Line yAxisId="left" type="monotone" dataKey="temperature" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.pressure ? (<Line yAxisId="right" type="monotone" dataKey="pressure" stroke="#8e67bf" />) : null}
          {chartDisplay.humidity ? (<Line yAxisId="left" type="monotone" dataKey="humidity" stroke="#8B0000" />) : null}
          {chartDisplay.leq_sound_level ? (<Line yAxisId="left" type="monotone" dataKey="leq_sound_level" stroke="#FF69B4" />) : null}
          {chartDisplay.illuminance_sensor ? (<Line yAxisId="right" type="monotone" dataKey="illuminance_sensor" stroke="#FFD700" />) : null}
        </LineChart>
      </ResponsiveContainer>
      );
    };

    const isLightDevice = this.props.deviceIdSelected.startsWith("LT");

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop={false}
        dialogClassName="rightAlignedModal" 
        centered 
        backdropClassName="modal-backdrop"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>{this.props.deviceIdSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <div style={{ minHeight: '500px' }}>
            {RenderRechart(chartData, chartDisplay)}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Button variant="secondary" onClick={onHide}>Close</Button>
          <Button variant="primary" onClick={() => console.log('Brightness set to:', brightness)}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default QseModal;