import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import {IAcuData, IAcuMetaData, ExtendedIAcuData} from '../../../firebase/types/acu.type';
import {DeviceName, AcuChartData, AcuChartDisplay} from '../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen, secondsToIso } from '../../../utils/timestamp';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryIAcuData} from '../../../firebase/types/acu.type';
import { modeToNumber } from '../../../utils/acstatus';
import './modal.css';
import './buttonstyles.css';

interface AcuModalProps {
  show: boolean;
  onHide: () => void;
  temperature: number;
  setTemperature: (value: number) => void;
  deviceIdSelected:string;
}

type State = {
  flattenedPointset: Array<ExtendedIAcuData>,
  metadata: Array<IAcuMetaData>,
  brickschema: {},
  loading: false,
  device: [],
  chartData: AcuChartData;
  chartDisplay: AcuChartDisplay;
};

class AcuModal extends Component<AcuModalProps, State> {
  unsubscribe: () => void;

  componentDidUpdate(prevProps: AcuModalProps) {
    // Check if deviceIdSelected has changed
    if (this.props.deviceIdSelected !== prevProps.deviceIdSelected && this.props.deviceIdSelected.startsWith("ACU")) {
        this.runFirstDatabaseQuery(); // Call the function to fetch new data based on the new deviceIdSelected
    }
}

  componentDidMount() {
    // this.fetchTableData();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
  }
  }

  constructor(props: AcuModalProps) {
    super(props);
    
    this.state = {
      flattenedPointset: [],
      metadata: [],
      brickschema: {},
      loading: false,
      device: [],
      chartData:[
        { timestamp: "2022-08-28T10:50:00.000Z", 
          fan_speed: 1,
          run_mode:1,
          run_status: 1,
          temperature:33.8,
          temperature_setpoint: 25.2,
          compare_fan_speed: 3,
          compare_run_mode:3,
          compare_run_status: 3,
          compare_temperature:20.8,
          compare_temperature_setpoint: 25.2,
        },
        { timestamp: "2022-09-04T08:30:00.000Z", 
            fan_speed: 2,
            run_mode:2,
            run_status: 2,
            temperature:35.8,
            temperature_setpoint: 27.2,
            compare_fan_speed: 2,
            compare_run_mode:1,
            compare_run_status: 1,
            compare_temperature:23.8,
            compare_temperature_setpoint: 25.2,
        }
      ],
      chartDisplay:{
        fan_speed: true,
        run_mode:true,
        run_status: true,
        temperature:true,
        temperature_setpoint: true,
        compare_fan_speed: false,
        compare_run_mode: false,
        compare_run_status:false,
        compare_temperature: false,
        compare_temperature_setpoint:false,
      },
    };
    this.runFirstDatabaseQuery();
    this.unsubscribe = () => { };
  }

  flattenObjects(historyObjectArray: HistoryIAcuData[]){
    let chartData: AcuChartData = historyObjectArray.map(item => ({
        timestamp: secondsToIso(item.timestamp.seconds),
        fan_speed: modeToNumber(item.points.fan_speed?.present_value) ?? 0,
        run_mode: modeToNumber(item.points.run_mode?.present_value) ?? 0,
        run_status: modeToNumber(item.points.run_status?.present_value) ?? 0,
        temperature: item.points.temperature?.present_value ?? 0,
        temperature_setpoint: item.points.temperature_setpoint?.present_value ?? 0,
        compare_fan_speed:  0,
        compare_run_mode:  0,
        compare_run_status:  0,
        compare_temperature: 0,
        compare_temperature_setpoint: 0
      }));
    return chartData;
};

  checkLevel(deviceId:String) {
    // Split the string by '-' and check if it has the expected format
    const parts = deviceId.split('-');
    if (parts.length === 2) {
        const firstTwoDigits = parts[1].slice(0, 2); // Extract the first two digits after '-'

        if (firstTwoDigits === "01") {
            return "Level-01";
        } else if (firstTwoDigits === "00") {
            return "Level-00";
        } else {
            return "Level-01";
        }
    } else {
        return "Level-01";
    }
}

  runFirstDatabaseQuery = async () => {  
    let queryResult: HistoryIAcuData[] = [];
    // Set the end date to the current time
    const end = new Date();
    const deviceIdSelected = this.props.deviceIdSelected;

    console.log("modal runFirstDatabaseQuery:" + deviceIdSelected)

    // Set the start date to 8 hours before the end date
    const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
    console.log('runFirstDatabaseQuery startDate:' + start + ' endDate:' + end);

    //LuminaireDataService.getDeviceTimeseries(this.props.level
    LuminaireDataService.getDeviceTimeseries(this.checkLevel(deviceIdSelected), deviceIdSelected, start, end)
    .get()
    .then((querySnapshot: any) => {
        querySnapshot.forEach((doc:any) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            queryResult.push(doc.data());
        });
    }).then(()=> {
        console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
        this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
          // this.handleFilterData();
      });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });

  };

  render() {
    const { show, onHide, temperature, setTemperature } = this.props;
    const { chartData, chartDisplay} = this.state;

    const calculateMaxYValue = (chartData:AcuChartData, chartDisplay:AcuChartDisplay) => {
      if (!Array.isArray(chartData) || chartData.length === 0) {
        return 100;
      }

      return Math.max(
        ...chartData.map(d => Math.max(
          chartDisplay.fan_speed ? d.fan_speed ?? 0 : 0,
          chartDisplay.run_mode ? d.run_mode ?? 0 : 0,
          chartDisplay.run_status ? d.run_status ?? 0 : 0,
          chartDisplay.temperature ? d.temperature ?? 0 : 0,
          chartDisplay.temperature_setpoint ? d.temperature_setpoint ?? 0 : 0,
          chartDisplay.compare_fan_speed ? d.compare_fan_speed ?? 0 : 0,
          chartDisplay.compare_run_mode ? d.compare_run_mode ?? 0 : 0,
          chartDisplay.compare_run_status ? d.compare_run_status ?? 0 : 0,
          chartDisplay.compare_temperature ? d.compare_temperature ?? 0 : 0,
          chartDisplay.compare_temperature_setpoint ? d.compare_temperature_setpoint ?? 0 : 0
        ))
      );
    };

    const RenderRechart = (chartData:AcuChartData, chartDisplay:AcuChartDisplay) => {
      return(
        <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={createHumanTimestamp}
            // ticks={this.calculateTicks(chartData, 7)}
            angle={-45} 
            textAnchor="end"
            height={120}
          />
          <YAxis label={{ value: "#", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
          {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
          <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 30]} interval={0}/>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {chartDisplay.fan_speed ? (<Line yAxisId="left" type="monotone" dataKey="fan_speed" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.run_mode ? (<Line yAxisId="left" type="monotone" dataKey="run_mode" stroke="#82ca9d" />) : null}
          {chartDisplay.run_status ? (<Line yAxisId="left" type="monotone" dataKey="run_status" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.temperature ? (<Line yAxisId="right" type="monotone" dataKey="temperature" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.temperature_setpoint ? (<Line yAxisId="right" type="monotone" dataKey="temperature_setpoint" stroke="#8e67bf" />) : null}
        </LineChart>
      </ResponsiveContainer>
      );
    };

    const isLightDevice = this.props.deviceIdSelected.startsWith("LT");

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop={false}
        dialogClassName="rightAlignedModal" 
        centered 
        backdropClassName="modal-backdrop"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>{this.props.deviceIdSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
        <p>Adjust the {isLightDevice ? 'brightness' : 'temperature:'} for {isLightDevice ? 'light' : 'air conditioning'}</p>
          <Form>
            <Form.Group>
              <Form.Label>{isLightDevice ? 'Brightness:' : 'Temperature:'} {temperature} {isLightDevice ? '%' : '°C'}</Form.Label>
              <Form.Control
                type="range"
                min="18"
                max="24"
                value={temperature}
                onChange={e => setTemperature(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
          <div style={{ minHeight: '500px' }}>
            {RenderRechart(chartData, chartDisplay)}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Button variant="secondary" onClick={onHide}>Close</Button>
          <Button variant="primary" onClick={() => console.log('temperature set to:', temperature)}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AcuModal;