export const MonitoringNav = [
  {
    icon: "pe-7s-rocket",
    label: "Dashboards",
    content: [
      {
        label: "AHU",
        to: "#/dashboards/ahu/level-01",
      },
      {
        label: "CHL",
        to: "#/dashboards/chill/level-01",
      },
      {
        label: "EASY",
        to: "#/dashboards/easy/level-01",
      },
      {
        label: "FCU",
        to: "#/dashboards/fcu/level-01",
      },
      {
        label: "LAYOUT",
        to: "#/dashboards/layout/level-01",
      },
      {
        label: "LTGW",
        to: "#/dashboards/ltgw/level-01",
      },
      {
        label: "LTHW",
        content: [
          {
            label: "LTHW",
            to: "#/dashboards/lthw/level-01",
          },
          {
            label: "LTHW_AHU_FCU_Pumps",
            to: "#/dashboards/lthw_ahu_fcu_pumps/level-01",
          },
          {
            label: "LTHW_VT_RAD_Pumps",
            to: "#/dashboards/lthw_vt_rad_pumps/level-01",
          },
          {
            label: "LTHW_DHWS_Pumps",
            to: "#/dashboards/lthw_dhws_pumps/level-01",
          },
        ],
      },
      {
        label: "TANK",
        to: "#/dashboards/tank/level-01",
      },
      {
        label: "VAV",
        to: "#/dashboards/vav/level-01",
      },
    ],
  },
  {
    icon: "pe-7s-browser",
    label: "UserPages",
    content: [
      {
        label: "Login",
        to: "#/pages/login",
      },
      {
        label: "Login Boxed",
        to: "#/pages/login-boxed",
      },
      {
        label: "Register",
        to: "#/pages/register",
      },
      {
        label: "Register Boxed",
        to: "#/pages/register-boxed",
      },
      {
        label: "Forgot Password",
        to: "#/pages/forgot-password",
      },
      {
        label: "Forgot Password Boxed",
        to: "#/pages/forgot-password-boxed",
      },
    ],
  },
];

export interface NavMenuItem {
  icon?: string;
  labelAbreviation: string;
  label: string;
  to?: string;
  content?: NavMenuItem[];
}

/*
  MAP
  STATIONS OVERVIEW
  ALARSM
  REPORTS
  SECURITY
  SYSTEMS
  NETWORK
  CHARTS | Historian | Time Series
  Logs
  Accounts User
  Services
  Floor Plans
  Schedules
  MESSAGE PANEL
  FLOWS
  */

  export const PreNewNav = [
    {
      icon: "pe-7s-browser",
      label: "Dashboards",
      labelAbreviation: "DASH",
      content: [
        {
          icon: "lnr lnr-apartment",
          label: "Space",
          labelAbreviation: "SPACE",
          to: "/dashboards/space",
        },
        {
          icon: "lnr lnr-cloud",
          label: "Air",
          labelAbreviation: "AIR",
          to: "/dashboards/air",
        },
        {
          icon: "pe-7s-box2",
          label: "3D Modeling",
          labelAbreviation: "BIM",
          to: "/dashboards/bim",
        },
        {
          icon: "pe-7s-joy",
          label: "Room Control",
          labelAbreviation: "CTRL",
          to: "/dashboards/air",
        },
        {
          icon: "pe-7s-gleam",
          label: "Metering Electric",
          labelAbreviation: "MTR ELEC",
          to: "/dashboards/energy",
        },
        {
          icon: "pe-7s-repeat",
          label: "Fan Coil Unit",
          labelAbreviation: "FCU",
          to: "/dashboards/fcu",
        },
        {
          icon: "pe-7s-shuffle",
          label: "Air Handling Unit",
          labelAbreviation: "AHU",
          to: "/dashboards/ahu",
        },
        {
          icon: "lnr lnr-cog",
          label: "Chiller",
          labelAbreviation: "CHL",
          to: "/dashboards/chill",
        },
        {
          icon: "pe-7s-gleam",
          label: "Metering Electric",
          labelAbreviation: "MTR ELEC",
          to: "/dashboards/meter",
        },
        {
          icon: "lnr lnr-database",
          label: "Pumps",
          labelAbreviation: "PUMP",
          to: "#/dashboards/lthw_ahu_fcu_pumps/level-01",
        },
        {
          icon: "lnr lnr-text-align-center",
          label: "Radiators",
          labelAbreviation: "RAD",
          to: "#/dashboards/lthw_vt_rad_pumps/level-01",
        },
        {
          icon: "lnr lnr-drop",
          label: "Low Temp Hot Water",
          labelAbreviation: "LTHW",
          to: "#/dashboards/lthw/level-01",
        },
        {
          icon: "pe-7s-network",
          label: "Dom Hot Water System",
          labelAbreviation: "DHWS",
          to: "#/dashboards/lthw_dhws_pumps/level-01",
        },
        {
          icon: "pe-7s-sun",
          label: "Solar Photovoltaic",
          labelAbreviation: "SOLAR PV",
          to: "#/dashboards/lthw_dhws_pumps/level-01",
        },
      ],
    },
    {
      icon: "lnr lnr-users",
      label: "Administrator",
      labelAbreviation: "ADMIN",
      content: [
        {
          icon: "lnr-move",
          label: "Graphic Editor",
          labelAbreviation: "GRAPHICS",
          to: "/dashboards/admin/graphic-editor",
        },
      ],
    },
    {
      icon: "pe-7s-folder",
      label: "Documentation",
      labelAbreviation: "DOCS",
      content: [
        {
          icon: "pe-7s-notebook",
          label: "Tutorials",
          labelAbreviation: "TUT",
          to: "/tutorials",
        },
        {
          icon: "pe-7s-note2",
          label: "Articles",
          labelAbreviation: "ART",
          to: "/articles",
        },
      ],
    },
  ];

  export const EmptyNav = [];
export const OldNav = [
  {
    icon: "pe-7s-browser",
    label: "Dashboards",
    labelAbreviation: "DASH",
    content: [
      {
        icon: "lnr lnr-apartment",
        label: "Space",
        labelAbreviation: "SPACE",
        to: "/dashboards/space",
      },
      {
        icon: "lnr lnr-cloud",
        label: "Air",
        labelAbreviation: "AIR",
        to: "/dashboards/air",
      },
      {
        icon: "pe-7s-box2",
        label: "3D Modeling",
        labelAbreviation: "BIM",
        to: "/dashboards/bim",
      },
      {
        icon: "pe-7s-joy",
        label: "Room Control",
        labelAbreviation: "CTRL",
        to: "/dashboards/air",
      },
      {
        icon: "pe-7s-gleam",
        label: "Metering Electric",
        labelAbreviation: "MTR ELEC",
        to: "/dashboards/energy",
      },
      {
        icon: "pe-7s-gleam",
        label: "Utilisation Metrics",
        labelAbreviation: "METRICS",
        to: "/dashboards/metrics",
      },
      {
        icon: "pe-7s-repeat",
        label: "Fan Coil Unit",
        labelAbreviation: "FCU",
        to: "/dashboards/fcu",
      },
      {
        icon: "pe-7s-shuffle",
        label: "Air Handling Unit",
        labelAbreviation: "AHU",
        to: "/dashboards/ahu",
      },
      {
        icon: "lnr lnr-cog",
        label: "Chiller",
        labelAbreviation: "CHL",
        to: "/dashboards/chill",
      },
      {
        icon: "pe-7s-gleam",
        label: "Metering Electric",
        labelAbreviation: "MTR ELEC",
        to: "/dashboards/meter",
      },
    ]
    }
];

export const SimmtronicNav = [
  {
    icon: "pe-7s-users",
    label: "Digital Twin",
    labelAbreviation: "TWIN",
    content: [
      // {
      //   icon: "lnr lnr-apartment",
      //   label: "Space",
      //   labelAbreviation: "SPACE",
      //   to: "/dashboards/space",
      // },
      {
        icon: "pe-7s-box2",
        label: "3D Modeling",
        labelAbreviation: "BIM",
        to: "/dashboards/bimodel",
      },
      // {
      //   icon: "pe-7s-joy",
      //   label: "Room Control",
      //   labelAbreviation: "CTRL",
      //   to: "/dashboards/ctrl",
      // }
    ]
  },
  {
    icon: "pe-7s-light",
    label: "Lighting",
    labelAbreviation: "LIGHTS",
    content: [
      {
        icon: "pe-7s-light",
        label: "Luminaire",
        labelAbreviation: "LT",
        to: "/dashboards/luminaire",
      },
      {
        icon: "pe-7s-network",
        label: "Control Module",
        labelAbreviation: "LCM",
        to: "/dashboards/lightctrl",
      },
      {
        icon: "pe-7s-expand1",
        label: "Area Controller",
        labelAbreviation: "LAC",
        to: "/dashboards/lightareactrl",
      },
      {
        icon: "pe-7s-volume",
        label: "Sensor",
        labelAbreviation: "MTS",
        to: "/dashboards/sensor",
      },
      {
        icon: "pe-7s-volume",
        label: "Environment Sensor",
        labelAbreviation: "SPQ.7",
        to: "/dashboards/spq7",
      },
      // {
      //   icon: "pe-7s-graph1",
      //   label: "Environment Sensor",
      //   labelAbreviation: "ETS",
      //   to: "/dashboards/envsensor",
      // },
      {
        icon: "pe-7s-keypad",
        label: "Keypad",
        labelAbreviation: "LKP",
        to: "/dashboards/lightkeypad",
      }
    ]
  },
  {
    icon: "pe-7s-home",
    label: "Building Management",
    labelAbreviation: "BMS",
    content: [
      // {
      //   icon: "pe-7s-alarm",
      //   label: "Alarms",
      //   labelAbreviation: "ALARM",
      //   to: "/dashboards/bmsalarm",
      // },
      // {
      //   icon: "pe-7s-date",
      //   label: "Schedules",
      //   labelAbreviation: "SCHED",
      //   to: "/dashboards/bmssched",
      // },
      {
        icon: "pe-7s-repeat",
        label: "Fan Coil Unit",
        labelAbreviation: "FCU",
        to: "/dashboards/fcu",
      },
      {
        icon: "pe-7s-repeat",
        label: "Air Conditioning Unit",
        labelAbreviation: "ACU",
        to: "/dashboards/acu",
      },
      // {
      //   icon: "pe-7s-shuffle",
      //   label: "Air Handling Unit",
      //   labelAbreviation: "MVHR",
      //   to: "/dashboards/mvhr",
      // }
    ]
    },
    {
      icon: "pe-7s-gleam",
      label: "Energy Management",
      labelAbreviation: "ENERGY",
      content: [
        // {
        //   icon: "pe-7s-timer",
        //   label: "Utilisation Metrics",
        //   labelAbreviation: "METRICS",
        //   to: "/dashboards/metrics",
        // },
        {
          icon: "pe-7s-graph3",
          label: "Metering Electric",
          labelAbreviation: "MTR ELEC",
          to: "/dashboards/netvox3phase",
        },
        // {
        //   icon: "lnr lnr-cog",
        //   label: "Chiller",
        //   labelAbreviation: "CHL",
        //   to: "/dashboards/chill",
        // },
        // {
        //   icon: "pe-7s-gleam",
        //   label: "Mtr Electric",
        //   labelAbreviation: "MTR ELEC",
        //   to: "/dashboards/meter",
        // },
      ]
    },
    {
      icon: "pe-7s-unlock",
      label: "Security",
      labelAbreviation: "SECURITY",
      content: [
        // {
        //   icon: "pe-7s-timer",
        //   label: "Utilisation Metrics",
        //   labelAbreviation: "METRICS",
        //   to: "/dashboards/metrics",
        // },
        {
          icon: "pe-7s-camera",
          label: "Cameras",
          labelAbreviation: "AJAX CAM",
          to: "/dashboards/ajaxcam",
        },
        // {
        //   icon: "lnr lnr-cog",
        //   label: "Chiller",
        //   labelAbreviation: "CHL",
        //   to: "/dashboards/chill",
        // },
        // {
        //   icon: "pe-7s-gleam",
        //   label: "Mtr Electric",
        //   labelAbreviation: "MTR ELEC",
        //   to: "/dashboards/meter",
        // },
      ]
    }
];

export const NewNav = [
  {
    icon: "pe-7s-users",
    label: "Digital Twin",
    labelAbreviation: "TWIN",
    content: [
      // {
      //   icon: "lnr lnr-apartment",
      //   label: "Space",
      //   labelAbreviation: "SPACE",
      //   to: "/dashboards/space",
      // },
      {
        icon: "pe-7s-box2",
        label: "3D Modeling",
        labelAbreviation: "BIM",
        to: "/dashboards/bimodel",
      },
      // {
      //   icon: "pe-7s-joy",
      //   label: "Room Control",
      //   labelAbreviation: "CTRL",
      //   to: "/dashboards/ctrl",
      // }
    ]
  },
  {
    icon: "pe-7s-light",
    label: "Lighting",
    labelAbreviation: "LIGHTS",
    content: [
      {
        icon: "pe-7s-light",
        label: "Luminaire",
        labelAbreviation: "LT",
        to: "/dashboards/luminaire",
      },
      {
        icon: "pe-7s-network",
        label: "Control Module",
        labelAbreviation: "LCM",
        to: "/dashboards/lightctrl",
      },
      {
        icon: "pe-7s-expand1",
        label: "Area Controller",
        labelAbreviation: "LAC",
        to: "/dashboards/lightareactrl",
      },
      {
        icon: "pe-7s-volume",
        label: "Sensor",
        labelAbreviation: "MTS",
        to: "/dashboards/sensor",
      },
      {
        icon: "pe-7s-volume",
        label: "Environment Sensor",
        labelAbreviation: "SPQ.7",
        to: "/dashboards/spq7",
      },
      // {
      //   icon: "pe-7s-graph1",
      //   label: "Environment Sensor",
      //   labelAbreviation: "ETS",
      //   to: "/dashboards/envsensor",
      // },
      {
        icon: "pe-7s-keypad",
        label: "Keypad",
        labelAbreviation: "LKP",
        to: "/dashboards/lightkeypad",
      }
    ]
  },
  {
    icon: "pe-7s-home",
    label: "Building Management",
    labelAbreviation: "BMS",
    content: [
      // {
      //   icon: "pe-7s-alarm",
      //   label: "Alarms",
      //   labelAbreviation: "ALARM",
      //   to: "/dashboards/bmsalarm",
      // },
      // {
      //   icon: "pe-7s-date",
      //   label: "Schedules",
      //   labelAbreviation: "SCHED",
      //   to: "/dashboards/bmssched",
      // },
      {
        icon: "pe-7s-repeat",
        label: "Fan Coil Unit",
        labelAbreviation: "FCU",
        to: "/dashboards/fcu",
      },
      {
        icon: "pe-7s-repeat",
        label: "Air Conditioning Unit",
        labelAbreviation: "ACU",
        to: "/dashboards/acu",
      },
      // {
      //   icon: "pe-7s-shuffle",
      //   label: "Air Handling Unit",
      //   labelAbreviation: "MVHR",
      //   to: "/dashboards/mvhr",
      // }
    ]
    },
    {
      icon: "pe-7s-gleam",
      label: "Energy Management",
      labelAbreviation: "ENERGY",
      content: [
        // {
        //   icon: "pe-7s-timer",
        //   label: "Utilisation Metrics",
        //   labelAbreviation: "METRICS",
        //   to: "/dashboards/metrics",
        // },
        {
          icon: "pe-7s-graph3",
          label: "Metering Electric",
          labelAbreviation: "MTR ELEC",
          to: "/dashboards/netvox3phase",
        },
        // {
        //   icon: "lnr lnr-cog",
        //   label: "Chiller",
        //   labelAbreviation: "CHL",
        //   to: "/dashboards/chill",
        // },
        // {
        //   icon: "pe-7s-gleam",
        //   label: "Mtr Electric",
        //   labelAbreviation: "MTR ELEC",
        //   to: "/dashboards/meter",
        // },
      ]
    },
    {
      icon: "pe-7s-unlock",
      label: "Security",
      labelAbreviation: "SECURITY",
      content: [
        // {
        //   icon: "pe-7s-timer",
        //   label: "Utilisation Metrics",
        //   labelAbreviation: "METRICS",
        //   to: "/dashboards/metrics",
        // },
        {
          icon: "pe-7s-camera",
          label: "Cameras",
          labelAbreviation: "AJAX CAM",
          to: "/dashboards/ajaxcam",
        },
        // {
        //   icon: "lnr lnr-cog",
        //   label: "Chiller",
        //   labelAbreviation: "CHL",
        //   to: "/dashboards/chill",
        // },
        // {
        //   icon: "pe-7s-gleam",
        //   label: "Mtr Electric",
        //   labelAbreviation: "MTR ELEC",
        //   to: "/dashboards/meter",
        // },
      ]
    }
];

export const ElectracomNav = [
  {
    icon: "pe-7s-browser",
    label: "Dashboards",
    labelAbreviation: "DASH",
    content: [
      {
        icon: "lnr lnr-cog",
        label: "Chiller",
        labelAbreviation: "CHL",
        to: "/dashboards/chill",
      },
      {
        icon: "pe-7s-gleam",
        label: "Metering Electric",
        labelAbreviation: "MTR ELEC",
        to: "/dashboards/meter",
      },
    ]
    }
];

export const FaultNav = [
  {
    icon: "pe-7s-monitor",
    label: "Dashboards",
    content: [
      {
        label: "FCU",
        to: "/dashboards/fcu",
      },
      {
        label: "AHU",
        to: "/dashboards/ahu",
      },
      {
        label: "LTHW",
        to: "/dashboards/lthw",
      },
      {
        label: "LTHW_AHU_FCU_Pumps",
        to: "/dashboards/lthw_ahu_fcu_pumps",
      },
      {
        label: "LTHW_VT_RAD_Pumps",
        to: "/dashboards/lthw_vt_rad_pumps",
      },
      {
        label: "LTHW_DHWS_Pumps",
        to: "/dashboards/lthw_dhws_pumps",
      },
    ],
  },
];
export const CommNav = [
  {
    icon: "pe-7s-rocket",
    label: "Communication",
    content: [
      {
        label: "Tickets",
        to: "#/applications/tickets",
      },
      {
        label: "Chat",
        to: "#/applications/chat",
      },
    ],
  },
];
export const ChartNav = [
  {
    icon: "pe-7s-rocket",
    label: "Charts",
    content: [
      {
        label: "sparklines-1",
        to: "#/charts/sparklines-1",
      },
      {
        label: "sparklines-2",
        to: "#/charts/sparklines-2",
      },
      {
        label: "chartjs",
        to: "#/charts/chartjs",
      },
      {
        label: "gauges",
        to: "#/charts/gauges",
      },
      {
        label: "apexcharts",
        to: "#/charts/apexcharts",
      },
    ],
  },
];
