import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import './styles.css'

import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 

import { BuildingState } from '../../../../redux/types/building.type';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

import LightControlModal from "../../../components/luminaireModal/modal";
import QseModal from "../../../components/spq7Modal/modal";
import AcuModal from "../../../components/acuModal/modal";

  type Props = {
    buildings: BuildingState
  };
  
  type State = {
      dropdownOpen: false;
      selectedOption: null;
      loading: false;
      device: [];
      lightingModalShow: boolean;
      acuModalShow: boolean;
      qseModalShow: boolean;
      brightness: number;
      temperature:number;
      deviceIdSelected: string;
  };
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    private ws: WebSocket | null = null;

    constructor(props: Props) {
      super(props);

      this.state = {
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        lightingModalShow: false,
        acuModalShow: false,
        qseModalShow: false,
        brightness: 44,
        temperature: 23,
        deviceIdSelected:"LT-000131"
      };

    }

    componentDidMount() {
      this.connectWebSocket();
    }
  
    componentWillUnmount() {
      if (this.ws) {
        this.ws.close();
      }
    }
 
    setBrightness = (brightness: number) => {
      this.setState({ brightness });
    }
    setTemperature = (temperature: number) => {
      this.setState({ temperature });
    }


    handleAsset(deviceName: string): void {
      if(deviceName.includes('-')){
        // Extract the prefix by splitting the input string by '-' and taking the first part
        const prefix = deviceName.split('-')[0];
      
        switch (prefix) {
          case 'MVHR':
            console.log('Handling MVHR prefix');
            // Add more specific code to handle MVHR cases here
            break;
          case 'ACU':
            console.log('Handling ACU prefix');
            this.setState({ deviceIdSelected: deviceName });
            this.setState({ lightingModalShow: false, qseModalShow:false, acuModalShow: true });
            break;
          case 'LT':
            console.log('Handling LT prefix');
            this.setState({ deviceIdSelected: deviceName });
            this.setState({ qseModalShow:false, acuModalShow: false, lightingModalShow: true });
            break;
          case 'QSE':
            console.log('Handling QSE prefix');
            this.setState({ deviceIdSelected: deviceName });
            this.setState({ acuModalShow: false, lightingModalShow: false, qseModalShow: true });
            break;
          default:
            console.log('No specific handling for this prefix');
            // Code to handle cases where none of the prefixes match
            break;
        }
      }
    }
    
    connectWebSocket = () => {
      // Assuming the WebSocket URL is ws://34.38.77.8
      // this.ws = new WebSocket('ws://localhost:8080');
      this.ws = new WebSocket('wss://bim.thaniumos.com');
  
      this.ws.onopen = () => {
        console.log('REACT WebSocket Connected');
        // You can send messages to the server here using this.ws.send(data)
      };
  
      this.ws.onerror = (error) => {
        console.log('REACT WebSocket Error: ', error);
      };
  
      this.ws.onmessage = (e: MessageEvent) => {
        // Check if the received data is a Blob
        if (e.data instanceof Blob) {
          // Create a FileReader to read the Blob
          const reader = new FileReader();
          
          // Define what happens on successful data read
          reader.onload = () => {
            // This will log the text content that was read
            const deviceName = reader.result as string;
            console.log('REACT Blob WebSocket message received:', deviceName);
            this.handleAsset(deviceName);
            
            // if(deviceName.includes('-') && (deviceName.split('-')[0] === 'LT')){
            //   this.setState({ deviceIdSelected: deviceName });
            //   this.setState({ lightingModalShow: true });
            // }
          };
      
          // Define what happens in case of an error
          reader.onerror = (error) => {
            console.error('Error reading the Blob data:', error);
          };
      
          // Start reading the Blob as text
          reader.readAsText(e.data);
        } else {
          // If it's not a Blob, assume it's already text (or handle other cases as needed)
          console.log('REACT !Blob WebSocket message received:', e.data);
          let deviceName = e.data;
          this.handleAsset(deviceName);
          // if(deviceName.includes('-') && (deviceName.split('-')[0] === 'LT')){
          //   this.setState({ deviceIdSelected: deviceName });
          //   this.setState({ lightingModalShow: true });
          // }
          
        }
      };
  
      this.ws.onclose = (e) => {
        console.log('REACT WebSocket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(() => {
          this.connectWebSocket();
        }, 1000);
      };
    }

      render() {
        //localhost:8080
        return (
          <>
          <div className="modal-overlay" onClick={() => this.setState({ lightingModalShow: false, qseModalShow:false, acuModalShow: false })}>
            
            <iframe 
                src="https://bim.thaniumos.com"
                title="BIM Iframe"
                width="100%"
                className="iframe-full-height"
                frameBorder="0"  // This removes the default border around the iframe. Most websites use this to make the iframe look cleaner.
                allowFullScreen  // This allows the iframe content to be viewed in full screen
            >
          <LightControlModal
              show={this.state.lightingModalShow}
              onHide={() => this.setState({ lightingModalShow: false })}
              brightness={this.state.brightness}
              setBrightness={this.setBrightness}
              deviceIdSelected={this.state.deviceIdSelected}
            />
            <QseModal
              show={this.state.qseModalShow}
              onHide={() => this.setState({ qseModalShow: false })}
              brightness={this.state.brightness}
              setBrightness={this.setBrightness}
              deviceIdSelected={this.state.deviceIdSelected}
            />
            <AcuModal
              show={this.state.acuModalShow}
              onHide={() => this.setState({ acuModalShow: false })}
              temperature={this.state.temperature}
              setTemperature={this.setTemperature}
              deviceIdSelected={this.state.deviceIdSelected}
            />

            </iframe>
          </div>
          </>
        );
    }

    
}